import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { HomeUpcomingComponent } from './home-upcoming/home-upcoming.component';
import { HomeTimelineComponent } from './home-timeline/home-timeline.component';
import { HomeTimelineViewComponent } from './home-timeline-view/home-timeline-view.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: HomeDashboardComponent,
      },
      {
        path: 'upcoming',
        component: HomeUpcomingComponent,
      },
      {
        path: 'timeline',
        component: HomeTimelineComponent,
      },
      {
        path: 'timeline/view/:id',
        component: HomeTimelineViewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
