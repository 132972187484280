import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { PostService } from 'src/app/main/services/post.services';
import moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { MediaMatcher } from '@angular/cdk/layout';
import { Post, UserDetails } from 'src/app/main/types/main.types';
import { Observable, Subject, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AppState } from 'src/app/main/store/model/state.model';
import { Store } from '@ngrx/store';
import { CLEAR_POST } from 'src/app/main/store/reducers/post.reducer';

@Component({
  selector: 'app-home-timeline',
  templateUrl: './home-timeline.component.html',
})
export class HomeTimelineComponent implements OnInit, AfterViewInit {
  currentPage = 1;
  posts: any = [];
  post: any;
  postId: number;
  commentVisible = false;
  mobileQuery: MediaQueryList;
  closable = true;
  moment = moment;
  isLoading = true;
  userPickerVisible = false;
  selectedUser: number[] = [];
  post$: Observable<Post>;
  userPickerEvent: Subject<any> = new Subject<any>();
  @ViewChild('dashboardContainer') dashboard: ElementRef;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private postService: PostService,
    private router: Router,
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private store: Store<AppState>,
  ) {
    this.mobileQuery = this.media.matchMedia('(min-width: 425px)');
    this.mobileQuery.addEventListener('change', () => {
      this.mobileQuery.matches
        ? (this.closable = true)
        : (this.closable = false);
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.getPosts(this.currentPage - 1);
    this.getPostState();
  }

  ngAfterViewInit(): void {
    this.clearPostState();
  }

  getPostState() {
    this.post$ = this.store
      .select((store) => store.post)
      .pipe(
        tap((d: Post) => {
          const _post = { ...d };
          if (_post?.id) {
            if (_post.type === 'REPOST') {
              _post.refPost.parameters?.forEach((p: any) => {
                if (_post.refPost.content)
                  _post.refPost.content = _post.refPost.content.replace(
                    new RegExp(`{{(${p.key})}}`, 'gi'),
                    `<strong>${p.value}</strong>`,
                  );
              });
            } else {
              _post.parameters?.forEach((p: any) => {
                if (_post.content)
                  _post.content = _post.content.replace(
                    new RegExp(`{{(${p.key})}}`, 'gi'),
                    `<strong>${p.value}</strong>`,
                  );
              });
            }
            this.posts.unshift(_post);
            this.clearPostState();
          }
        }),
      );
    this.post$.subscribe();
  }

  clearPostState() {
    this.store.dispatch({
      type: CLEAR_POST,
      payload: {},
    });
  }

  getPosts(page: number) {
    this.postService.getAllPosts(page).subscribe({
      next: (data: any) => {
        console.log('Request to get posts succeeded');
        const _list = data.map((d: Post) => {
          if (d.type === 'REPOST') {
            d.refPost.parameters.forEach((p: any) => {
              if (d.refPost.content)
                d.refPost.content = d.refPost.content.replace(
                  new RegExp(`{{(${p.key})}}`, 'gi'),
                  p.type === 'LOCATION'
                    ? `<i><strong>${p.value}</strong></i>`
                    : `<strong>${p.value}</strong>`,
                );
            });
          } else {
            d.parameters.forEach((p: any) => {
              if (d.content)
                d.content = d.content.replace(
                  new RegExp(`{{(${p.key})}}`, 'gi'),
                  p.type === 'LOCATION'
                    ? `<i><strong>${p.value}</strong></i>`
                    : `<strong>${p.value}</strong>`,
                );
            });
          }
          return d;
        });
        this.posts = [...this.posts, ..._list];
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Request to get get posts errored out.');
      },
    });
  }

  onScrolledDown() {
    this.currentPage++;
    this.getPosts(this.currentPage - 1);
  }

  scrollIntoView() {
    const id = this.router.parseUrl(this.router.url).fragment;
    if (id) {
      const container = this.doc.querySelector(
        '.dashboard-container .search-results',
      );
      const postEl = this.doc.getElementById(id);
      console.log(container?.childNodes);
    }
  }

  onCommentClick(post: Post) {
    this.commentVisible = true;
    this.post = post;
  }

  onPostDelete(id: any) {
    this.posts = this.posts.filter((p: Post) => p.id !== id);
  }

  sharePost(e: any) {
    this.userPickerVisible = true;
    this.postId = e;
    this.selectedUser = [];
    this.userPickerEvent.next([]);
    this.userPickerEvent.next('onLoad');
  }

  onList(e: UserDetails) {
    this.selectedUser.push(e.id);
  }

  onRemove(e: number) {
    this.selectedUser = this.selectedUser.filter((s) => s !== e);
  }

  onClickInvite(event: any) {
    this.postService
      .shareWithUsers({
        objectId: '' + this.postId,
        objectType: 'POST',
        userIds: this.selectedUser,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Share to Friend',
            detail: data.message,
          });
          this.userPickerVisible = false;
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Share to Friend',
            detail: err.error.message,
          });
        },
      });
  }
}
