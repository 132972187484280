import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/model/state.model';
import { App } from '../../types/main.types';
import { Observable, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  isLoading = false;

  appState$: Observable<App>;

  subscriptionRef: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isLoading = appState.loading;
        }),
      );
    this.subscriptionRef = this.appState$.subscribe();
  }
}
