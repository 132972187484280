import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { HomeUpcomingComponent } from './home-upcoming/home-upcoming.component';
import { HomeTimelineComponent } from './home-timeline/home-timeline.component';
import { HomeTimelineViewComponent } from './home-timeline-view/home-timeline-view.component';

import { DialogModule } from 'primeng/dialog';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { SkeletonModule } from 'primeng/skeleton';
import { DividerModule } from 'primeng/divider';

@NgModule({
  declarations: [
    HomeComponent,
    HomeDashboardComponent,
    HomeUpcomingComponent,
    HomeTimelineComponent,
    HomeTimelineViewComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    DialogModule,
    AvatarModule,
    InputTextareaModule,
    CardModule,
    InfiniteScrollModule,
    AvatarGroupModule,
    ButtonModule,
    CarouselModule,
    ChartModule,
    SharedModule,
    InputTextModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    TieredMenuModule,
    SkeletonModule,
    DividerModule,
  ],
})
export class HomeModule {}
