<div class="surface-ground p-2 pb-24">
  <div class="container mx-auto">
    <div class="flex grow flex-col">
      <div class="relative mb-2 w-full">
        <div
          class="grid grid-cols-1 gap-x-2 sm:grid-cols-8"
          *ngIf="chartsLoading"
        >
          <div
            class="col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-4 xl:col-span-2"
          >
            <p-skeleton height="206px"></p-skeleton>
          </div>
          <div
            class="col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-4 xl:col-span-2"
          >
            <p-skeleton height="206px"></p-skeleton>
          </div>
          <div
            class="col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-4 xl:col-span-2"
          >
            <p-skeleton height="206px"></p-skeleton>
          </div>
          <div
            class="col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-4 xl:col-span-2"
          >
            <p-skeleton height="206px"></p-skeleton>
          </div>
        </div>

        <div
          class="grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-8"
          *ngIf="!chartsLoading"
        >
          <div
            class="charts custom-style col-span-6 sm:col-span-4 md:col-span-4 lg:col-span-4 xl:col-span-2"
            *ngFor="let chart of charts"
          >
            <p-card styleClass="dashboard-first-row relative rounded-lg">
              <div class="relative z-10 px-2">
                <h4 class="highlight-text -mx-6 mx-2 py-2 font-semibold">
                  {{ chart.label }}
                  <sup>
                    <a (click)="scrollToBottom()" class="primary cursor-pointer"
                      >1</a
                    >
                  </sup>
                </h4>
                <p-divider styleClass="m-0"></p-divider>
              </div>
              <div
                class="chart-container p-2"
                [ngClass]="
                  chart.label === 'Hiking Score' ? 'mx-auto max-w-[200px]' : ''
                "
              >
                <p-chart
                  [type]="chart.type"
                  [data]="chart.data"
                  [height]="chart.height"
                  [width]="chart.width"
                  [plugins]="chart.plugins || []"
                  [options]="chart.options"
                ></p-chart>
              </div>
            </p-card>
          </div>
        </div>
      </div>
      <div class="mb-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-8">
        <div class="col-span-8 flex-1 md:col-span-5">
          <p-card class="flex-1" styleClass="h-full trends rounded-lg">
            <div class="px-2">
              <h4 class="highlight-text -mx-6 mx-2 py-3 font-semibold">
                Trends
                <sup>
                  <a (click)="scrollToBottom()" class="primary cursor-pointer"
                    >1</a
                  >
                </sup>
              </h4>
              <p-divider styleClass="m-0"></p-divider>
            </div>
            <div class="">
              <p-carousel
                [value]="trendsChart"
                [numVisible]="1"
                [numScroll]="1"
                [showIndicators]="true"
                [showNavigators]="false"
                [responsiveOptions]="responsiveOptions"
                styleClass="w-full"
                contentClass="trend-content"
              >
                <ng-template let-trendChart pTemplate="item">
                  <div class="w-full px-3 py-2">
                    <p-chart
                      [type]="trendChart.type"
                      [data]="trendChart.data"
                      height="374px"
                      [plugins]="trendChart.plugins || []"
                      [options]="trendChart.options"
                    ></p-chart>
                  </div>
                </ng-template>
              </p-carousel>
            </div>
          </p-card>
        </div>
        <div class="col-span-8 flex flex-1 flex-col gap-2 md:col-span-3">
          <p-card
            *ngIf="totalStat"
            class="flex-1"
            styleClass="h-full padding-clear rounded-lg"
          >
            <div class="px-2">
              <h4 class="highlight-text -mx-6 mx-2 py-3 font-semibold">
                {{ totalStat.label }}
                <sup>
                  <a (click)="scrollToBottom()" class="primary cursor-pointer"
                    >1</a
                  >
                </sup>
              </h4>
              <p-divider styleClass="m-0"></p-divider>
            </div>
            <div class="mx-3 mt-8 flex h-28 justify-between gap-3 px-2">
              <div
                *ngFor="let item of totalStat.items"
                class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
              >
                <span class="material-icons jumbo" [ngClass]="item.class">{{
                  item.icon
                }}</span>
                <span class="text-center text-xs">{{ item.title }}</span>
                <span class="highlight-text text-center text-sm font-bold">{{
                  item.value
                }}</span>
              </div>
            </div>
          </p-card>
          <p-card class="flex-1" styleClass="h-full padding-clear">
            <div class="px-2">
              <h4 class="highlight-text -mx-6 mx-2 py-3 font-semibold">
                Notification Summary
              </h4>
              <p-divider styleClass="m-0"></p-divider>
            </div>
            <div class="mx-3 mt-8 flex h-28 justify-between gap-3 px-2">
              <div
                class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
              >
                <span class="jumbo text-emerald-400">{{
                  notifications.countNewTotal
                }}</span>
                <span class="h-level-4 pb-2 text-center">New Notification</span>
              </div>
              <div
                class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
              >
                <span class="jumbo text-emerald-400">{{
                  notifications.countSinceLastSeen
                }}</span>
                <span class="h-level-4 pb-2 text-center"
                  >New Since Last Seen</span
                >
              </div>
              <div
                class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-md py-3 transition duration-300"
              >
                <span class="jumbo text-emerald-400">{{
                  notifications.lastSeenAt
                    ? moment().diff(moment(notifications.lastSeenAt), "days")
                    : "0"
                }}</span>
                <span class="h-level-4 pb-2 text-center"
                  >Days Since Last Seen</span
                >
              </div>
            </div>
          </p-card>
        </div>
      </div>

      <div class="dashboard mb-6 flex flex-col gap-2 xl:mx-auto xl:w-[720px]">
        <div class="col-span-6 flex-1 md:col-span-2">
          <div class="flex flex-col gap-2">
            <app-slider [isDark]="isDark" objectType="HUDDLE"></app-slider>
            <app-slider
              [isDark]="isDark"
              objectType="CARPOOL"
              [userInfo]="userInfo"
            ></app-slider>
          </div>
        </div>
        <div class="order-1 col-span-6 flex-1 md:col-span-2 xl:order-none">
          <h2 class="h-level-2 mt-3">Recent Activities</h2>
          <div class="flex flex-col gap-2">
            <div *ngFor="let post of posts">
              <app-post
                [isDashboard]="true"
                [cardType]="post.type"
                [post]="post"
                [userInfo]="userInfo"
                (postDelete)="onPostDelete($event)"
                (sharePost)="sharePost($event)"
              ></app-post>
            </div>
            <a
              *ngIf="posts.length"
              [routerLink]="[DEFAULT_HOME, 'timeline']"
              class="primary mp-button mp-button-filled mt-3 block cursor-pointer rounded-md px-5 py-2 text-center"
              >Get more updates in the news feed</a
            >
          </div>
        </div>
      </div>
      <section id="disclaimer">
        <div class="disclaimer -mx-2 flex w-full flex-col gap-1 p-3">
          <h4 class="text-base font-semibold">
            <sup>1</sup>Insufficient Data Available
          </h4>
          <div class="px-1">
            <p class="text-sm">
              We currently don’t have enough data to display meaningful
              insights. For more personalized results, please continue using
              MissionPeak and participate in huddles, carpools, and other
              activities.
            </p>
            <p class="pt-1 text-sm italic">
              Note: The charts are currently displaying sample data.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<app-dialog
  [visibleChange]="dialogEvent.asObservable()"
  [view]="'CARPOOL'"
  header="Create"
  [userDetails]="userInfo"
  width="50vw"
></app-dialog>

<p-dialog
  header="Select User"
  [(visible)]="userPickerVisible"
  [modal]="true"
  [style]="{ width: '50vw', position: 'relative' }"
  [breakpoints]="{ '960px': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [appendTo]="'body'"
>
  <div #inviteUser class="relative mb-2 overflow-hidden transition-[height]">
    <app-user-picker-new
      [ref]="inviteUser"
      [showSave]="true"
      [buttonText]="'Share'"
      (list)="onList($event)"
      (remove)="onRemove($event)"
      (submitUserPicker)="onClickInvite()"
      [events]="userPickerEvent.asObservable()"
    ></app-user-picker-new>
  </div>
</p-dialog>
