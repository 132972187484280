<div
  #dashboardContainer
  *ngIf="!isLoading"
  class="surface-ground dashboard-container h-full overflow-auto px-2"
>
  <div class="mx-auto max-w-[720px]">
    <div class="linear max mx-auto mb-24 transition-all duration-500">
      <div class="container mb-8 mt-2">
        <div
          class="search-results"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollUpDistance]="2"
          [infiniteScrollThrottle]="500"
          [infiniteScrollContainer]="dashboardContainer"
          (scrolled)="onScrolledDown()"
          [fromRoot]="true"
        >
          <div *ngFor="let post of posts" class="mb-2 last:mb-0" [id]="post.id">
            <app-post
              (postEvent)="onCommentClick($event)"
              [cardType]="post.type"
              [post]="post"
              (postDelete)="onPostDelete($event)"
              (sharePost)="sharePost($event)"
            ></app-post>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="commentVisible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [closable]="closable"
  styleClass="w-screen h-screen max-h-full md:w-[30rem] md:h-auto comment-dialog"
>
  <ng-template pTemplate="header">
    <div class="text-xl font-bold">
      <p-button *ngIf="!closable" (click)="commentVisible = false">
        <span class="material-icons">arrow_back</span>
      </p-button>
      <span class="h-level-3">{{ post?.title }}</span>
    </div>
  </ng-template>
  <div class="h-full">
    <div class="grid h-full grid-cols-1 gap-x-6 gap-y-8 py-4 sm:grid-cols-6">
      <div class="col-span-6 px-4 md:col-span-6">
        <div class="grow-1 stretch-1 flex-start flex items-center">
          <div class="mr-4 flex">
            <span class="inline">
              <div class="relative h-[40px] w-[40px]">
                <p-avatar
                  label="P"
                  styleClass="mr-2 h-[40px] w-[40px]"
                  shape="circle"
                  size="large"
                ></p-avatar>
              </div>
            </span>
          </div>
          <div class="grow">
            <div class="flex flex-col">
              <div class="">
                <span class="h-level-3">{{ post?.title }}</span>
              </div>
              <div class="">
                <span
                  class="block min-w-0 max-w-full break-words text-left text-[0.8rem]"
                >
                  {{ moment(post?.postedTime).fromNow() }}
                </span>
              </div>
            </div>
          </div>
          <div class="">
            <button pButton class="mx-3 h-[32px] rounded-full p-1">
              <span class="material-icons"> more_horiz </span>
            </button>
          </div>
        </div>
        <div class="my-2"></div>
        <div>
          <div class="relative">
            <div class="flex items-center justify-between pb-3">
              <div class="flex grow">
                <div class="flex grow items-center overflow-hidden">
                  <span class="z-0">
                    <span class="flex items-center pl-2">
                      <p-avatarGroup styleClass="gap-[10px]">
                        <p-avatar shape="circle" styleClass="bg-[#247dff]">
                          <span class="material-icons">thumb_up</span>
                        </p-avatar>
                      </p-avatarGroup>
                    </span>
                  </span>
                  <span class="w-[100px] truncate text-slate-600">
                    <span class="ml-2">{{ post?.totalReactionCount }}</span>
                  </span>
                </div>
                <div
                  class="relative z-0 flex shrink-0 items-stretch justify-between"
                >
                  <div class="flex grow items-center overflow-hidden">
                    <span class="sub-title hover:underline">
                      <span class="ml-2"
                        >{{ post?.totalCommentCount }} comments</span
                      >
                    </span>
                    <span class="sub-title hover:underline">
                      <span class="ml-2"
                        >{{ post?.totalShareCount }} shares</span
                      >
                    </span>
                    <span class="sub-title hover:underline">
                      <span class="ml-2">0 repost</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="border-t">
              <div class="-mx-1 flex">
                <div
                  class="shrink-1 mt-[4px] min-w-0 max-w-full flex-1 grow cursor-pointer rounded-md px-1 py-1 transition duration-300 hover:bg-[#e8e8e8]"
                >
                  <div class="flex items-center justify-center gap-2">
                    <span class="material-icons">thumb_up</span>
                    <span class="hidden md:block">Like</span>
                  </div>
                </div>
                <div
                  class="shrink-1 mt-[4px] min-w-0 max-w-full flex-1 grow cursor-pointer rounded-md px-1 py-1 transition duration-300 hover:bg-[#e8e8e8]"
                >
                  <div class="flex items-center justify-center gap-2">
                    <span class="material-icons">chat_bubble</span>
                    <span class="hidden md:block">Comment</span>
                  </div>
                </div>
                <div
                  class="shrink-1 mt-[4px] min-w-0 max-w-full flex-1 grow cursor-pointer rounded-md px-1 py-1 transition duration-300 hover:bg-[#e8e8e8]"
                >
                  <div class="flex items-center justify-center gap-2">
                    <span class="material-icons">repeat</span>
                    <span class="hidden md:block">Repost</span>
                  </div>
                </div>
                <div
                  class="shrink-1 mt-[4px] min-w-0 max-w-full flex-1 grow cursor-pointer rounded-md px-1 py-1 transition duration-300 hover:bg-[#e8e8e8]"
                >
                  <div class="flex items-center justify-center gap-2">
                    <span class="material-icons">share</span>
                    <span class="hidden md:block">Share</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-6 px-4 md:col-span-6">
        <div class="block flex h-40 grow flex-col items-center justify-center">
          <span class="material-icons !text-6xl text-gray-400">forum</span>
          <h4 class="font-bold">No comment yet</h4>
          <span>Be the first to comment</span>
        </div>
      </div>
    </div>
    <div class="sticky bottom-0">
      <div class="p-3 shadow-inner">
        <input
          placeholder="Comment"
          pInputText
          type="text"
          class="block h-12 h-8 w-full rounded-full border px-2 py-1.5"
        />
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog
  header="Select User"
  [(visible)]="userPickerVisible"
  [modal]="true"
  [style]="{ width: '50vw', position: 'relative' }"
  [breakpoints]="{ '960px': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [appendTo]="'body'"
>
  <div #inviteUser class="relative mb-2 overflow-hidden transition-[height]">
    <app-user-picker-new
      [ref]="inviteUser"
      [showSave]="true"
      [buttonText]="'Share'"
      (submitUserPicker)="onClickInvite($event)"
      [events]="userPickerEvent.asObservable()"
      (list)="onList($event)"
      (remove)="onRemove($event)"
    ></app-user-picker-new>
  </div>
</p-dialog>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>
