import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ChangeDetectorRef,
  PLATFORM_ID,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { _chart, trendsChart, stats } from 'src/app/main/utilities';
import {
  Chart,
  DialogEvent,
  UserDetails,
  NotificationSummary,
  App,
} from 'src/app/main/types/main.types';
import { PostService } from 'src/app/main/services/post.services';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { DashbaordService } from 'src/app/main/services/dashboard.service';
import { Post } from 'src/app/main/types/main.types';
// import { ApexNonAxisChartSeries } from 'ng-apexcharts';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/main/store/model/state.model';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Carousel } from 'primeng/carousel';
import { DEFAULT_HOME } from 'src/app/main/utilities';
import { MessageService } from 'primeng/api';
import { CLEAR_POST } from 'src/app/main/store/reducers/post.reducer';

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
})
export class HomeDashboardComponent implements OnInit, AfterViewInit {
  visible = false;

  loading = false;

  chartsLoading = true;

  showCharts = false;

  charts: Chart[] = [];

  totalStat: any;

  trendsChart: Chart[];

  responsiveOptions: any;

  posts: Post[] = [];

  userInfo: UserDetails;

  userDetail$: Observable<UserDetails>;

  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  moment = moment;

  isDark = false;

  isBrowser = false;

  DEFAULT_HOME = DEFAULT_HOME;

  notifications: NotificationSummary;

  userPickerVisible = false;

  postId: number;

  selectedUser: number[] = [];

  huddleImage: string | null = './assets/images/huddle-default-cover.svg';

  notification$: Observable<NotificationSummary>;

  appState$: Observable<App>;

  post$: Observable<Post>;

  userPickerEvent: Subject<any> = new Subject<any>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private postService: PostService,
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    @Inject(PLATFORM_ID) platformId: object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    Carousel.prototype.onTouchMove = () => {};
  }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: '1400px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '1220px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '1100px',
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.getPosts();
    this.getUserInfo();
    this.notification$ = this.store
      .select((store) => store.notifications)
      .pipe(
        tap((n: NotificationSummary) => {
          this.notifications = n;
        }),
      );
    this.notification$.subscribe();

    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
          this.charts = _chart(this.isDark);
          this.trendsChart = trendsChart(this.isDark);
          this.changeLabelColors();
        }),
      );
    this.appState$.subscribe();
    this.getPostState();
  }

  ngAfterViewInit(): void {
    this.loading = true;
    setTimeout(() => {
      this.charts = _chart(this.isDark);
      this.totalStat = stats;
      this.chartsLoading = false;
    }, 800);
    this.cdRef.detectChanges();
    this.clearPostState();
  }

  getPosts() {
    this.postService.getAllPosts(0).subscribe({
      next: (data: any) => {
        console.log('Request to get posts succeeded');
        const _list = data.map((d: any) => {
          if (d.type === 'REPOST') {
            d.refPost.parameters.forEach((p: any) => {
              if (d.refPost.content)
                d.refPost.content = d.refPost.content.replace(
                  new RegExp(`{{(${p.key})}}`, 'gi'),
                  `<strong>${p.value}</strong>`,
                );
            });
          } else {
            d.parameters.forEach((p: any) => {
              if (d.content)
                d.content = d.content.replace(
                  new RegExp(`{{(${p.key})}}`, 'gi'),
                  `<strong>${p.value}</strong>`,
                );
            });
          }
          return d;
        });
        this.posts = _list;
      },
      error: (err) => {
        console.error('Request to get get posts errored out.');
      },
    });
  }

  clearPostState() {
    this.store.dispatch({
      type: CLEAR_POST,
      payload: {},
    });
  }

  onPostDelete(id: any) {
    this.posts = this.posts.filter((post) => post.id !== id);
  }

  getUserInfo() {
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
        }),
      );
    this.userDetail$.subscribe();
  }

  changeLabelColors() {
    const legendsText = this.document.querySelectorAll(
      '.apexcharts-legend-text',
    );
    legendsText.forEach((label: any) => {
      label.style.color = this.isDark ? '#fff' : '#000';
    });

    setTimeout(() => {
      const tSpan = this.document.querySelectorAll('tspan');
      tSpan.forEach((span: any) => {
        span.style.fill = this.isDark ? '#fff' : '#000';
      });
    }, 100);
  }

  scrollToBottom() {
    const disclaimer: any = this.document.querySelector('.disclaimer');
    const matContent = this.document.querySelector('.mat-drawer-content');
    matContent?.scrollTo({
      top: disclaimer?.offsetTop,
      behavior: 'smooth',
    });
  }

  getProfilePic(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  sharePost(e: any) {
    this.userPickerVisible = true;
    this.postId = e;
    this.selectedUser = [];
    this.userPickerEvent.next([]);
    this.userPickerEvent.next('onLoad');
  }

  onList(e: UserDetails) {
    this.selectedUser.push(e.id);
  }

  onRemove(e: number) {
    this.selectedUser = this.selectedUser.filter((s) => s !== e);
  }

  getPostState() {
    this.post$ = this.store
      .select((store) => store.post)
      .pipe(
        tap((_post: Post) => {
          const d = { ..._post };
          if (d?.id) {
            if (d.type === 'REPOST') {
              d.refPost.parameters?.forEach((p: any) => {
                if (d.refPost.content)
                  d.refPost.content = d.refPost.content.replace(
                    new RegExp(`{{(${p.key})}}`, 'gi'),
                    `<strong>${p.value}</strong>`,
                  );
              });
            } else {
              d.parameters?.forEach((p: any) => {
                if (d.content)
                  d.content = d.content.replace(
                    new RegExp(`{{(${p.key})}}`, 'gi'),
                    `<strong>${p.value}</strong>`,
                  );
              });
            }
            this.posts.unshift(d);
            this.clearPostState();
          }
        }),
      );
    this.post$.subscribe();
  }

  onClickInvite() {
    this.postService
      .shareWithUsers({
        objectId: '' + this.postId,
        objectType: 'POST',
        userIds: this.selectedUser,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Share to Friend',
            detail: data.message,
          });
          this.userPickerVisible = false;
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Share to Friend',
            detail:
              err.error?.message || 'Something went wrong, please try again',
          });
          this.userPickerEvent.next('loaderStop');
        },
      });
  }
}
